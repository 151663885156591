import React from "react"
import { Link } from "gatsby"
import { useWpArchiveSelection } from "../../hooks/useWpArchiveSelection"

import "./Archive-List.scss"

const ArchiveListMobile = ({ width }) => {
  const selection = useWpArchiveSelection()

  return (
    <ul className="top-clearence mobile-archive-list-container gv-container">
      {selection.wpPage.archive.projectSelection.map((post, index) => {
        return (
          <li key={`${post.id}`}>
            {post.categories.nodes.map(category => {
              if (
                post.project.gallery !== null &&
                post.project.gallery.length > 0
              ) {
                const image = post.project.gallery[0]
                return (
                  <Link
                    key={`link_${index}`}
                    className="serif-size archive-link"
                    to={`/archive/${category.slug}/${post.slug}/${image.title
                      .toLowerCase()
                      .replaceAll(" ", "-")}`}
                  >
                    <ul className="artwork-meta-wrapper">
                      <li>{`${category.name}, ${post.title}`}</li>
                      <li>{`${post.project.specs},`}</li>
                      <li>{`${post.project.date}`}</li>
                    </ul>
                  </Link>
                )
              } else {
                return (
                  <h3
                    className="serif-size no-margin archive-link"
                    key={`link_${index}`}
                  >
                    <ul className="artwork-meta-wrapper">
                      <li>{`${category.name}, ${post.title}`}</li>
                      <li>{`${post.project.specs},`}</li>
                      <li>{`${post.project.date}`}</li>
                    </ul>
                  </h3>
                )
              }
            })}
          </li>
        )
      })}
    </ul>
  )
}

export default ArchiveListMobile
