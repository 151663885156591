import React from "react"
import Layout from "../components/Layout/Layout"
import ArchiveListDesktop from "../components/Archive-List-Desktop/Archive-List"
import ArchiveListMobile from "../components/Archive-List-Mobile/Archive-List"
import useWindowSize from "../hooks/useWindowDimensions"
import Seo from "../components/Seo"
const ArchivePage = () => {
  const { width } = useWindowSize()
  // selection.wpPage.archive.projectSelection.forEach(post => {
  //   post.categories.nodes.forEach(category => {
  //     console.log(category.slug)
  //     console.log(post.slug)
  //     if (post.project.gallery !== null && post.project.gallery.length > 0) {
  //       const image = post.project.gallery[0]
  //       console.log(
  //         `/archive/${category.slug}/${post.slug}/${image.title
  //           .toLowerCase()
  //           .replaceAll(" ", "-")}`
  //       )
  //     }
  //   })
  // })
  return (
    <Layout>
      <Seo title="archive" />
      <div className="gv-co ntainer">
        {width > 768 ? (
          <ArchiveListDesktop width={width} />
        ) : (
          <ArchiveListMobile />
        )}
      </div>
    </Layout>
  )
}

export default ArchivePage
