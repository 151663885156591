import { useStaticQuery, graphql } from "gatsby"
export const useWpArchiveSelection = () => {
  const data = useStaticQuery(
    graphql`
      query {
        wpPage(slug: { eq: "archive" }) {
          id
          archive {
            projectSelection {
              ... on WpPost {
                id
                title
                slug
                categories {
                  nodes {
                    slug
                    name
                  }
                }
                project {
                  date(formatString: "Y")
                  specs
                  gallery {
                    title
                    altText
                    description
                    localFile {
                      name
                      childImageSharp {
                        gatsbyImageData(
                          placeholder: BLURRED
                          formats: [WEBP, AUTO]
                        )
                        internal {
                          content
                          description
                          ignoreType
                          mediaType
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return data
}
