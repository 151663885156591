import React, { useState } from "react"
import { Link } from "gatsby"
import { motion } from "framer-motion"
import { useWpArchiveSelection } from "../../hooks/useWpArchiveSelection"

import "./Archive-List.scss"

const ArchiveListDesktop = ({ width }) => {
  const selection = useWpArchiveSelection()

  return (
    <ul className="top-clearence desktop-archive-list-container">
      {selection.wpPage.archive.projectSelection.map((post, index) => {
        return (
          <li className="indented-wrapper" key={`${post.id}`}>
            {post.categories.nodes.map(category => {
              if (
                post.project.gallery !== null &&
                post.project.gallery.length > 0
              ) {
                const image = post.project.gallery[0]
                return (
                  <HoverableLink
                    width={width}
                    key={`link_${index}`}
                    post={post}
                    categoryName={category.name}
                    destination={`/archive/${category.slug}/${
                      post.slug
                    }/${image.title.toLowerCase().replaceAll(" ", "-")}`}
                    title={post.title}
                    index={index}
                  />
                )
              } else {
                return (
                  <h3
                    className="serif-size no-margin archive-link"
                    key={`link_${index}`}
                  >
                    {`${category.name}, ${post.title}, ${post.project.specs}, ${post.project.date}`}
                  </h3>
                )
              }
            })}
          </li>
        )
      })}
    </ul>
  )
}

export default ArchiveListDesktop

const HoverableLink = ({ destination, title, post, categoryName, width }) => {
  // console.log(post.project)
  const [isHover, setIsHover] = useState(false)
  const linksAnimation = {
    hoverStart: {
      x: -width / 12,
    },
    hoverEnd: {
      x: 0,
    },
  }
  return (
    <motion.div
      onHoverStart={() => {
        setIsHover(true)
      }}
      onHoverEnd={() => {
        setIsHover(false)
      }}
      variants={linksAnimation}
      animate={isHover ? "hoverStart" : "hoverEnd"}
      transition={{ type: "tween", duration: 0.2 }}
    >
      <Link
        style={{ color: isHover ? "#004aff" : "black" }}
        className="serif-size archive-link"
        to={destination}
      >{`${categoryName}, ${title}, ${post.project.specs}, ${post.project.date}`}</Link>
    </motion.div>
  )
}
